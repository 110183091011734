
.muti-image-item {
  position: relative;
  .delete-image-item {
    position: absolute;
    top: -0;
    right: -20px;
  }
}

@primary-color: #7f7fd5;@link-color: #86a8e7;