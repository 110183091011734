.video-module {
    .video-player {
        width: 270px;
        height: 150px;
        object-fit: fill;
        margin-top: 20px;
    }
}

.video-list-body {
    .video-list-box {
        height: 420px;
        margin: 8px;

        .video-item {
            margin-top: 15px;
            border: 1px solid #fff;
            text-align: center;
            padding: 10px;

            .video-item-player {
                width: 250px;
                height: 140px;
                object-fit: fill;
            }
        }

        .video-item:hover {
            border: 1px solid #f2f2f2;
        }
    }

    .video-footer {
        padding: 8px;

        .footer-operate {
            .footer-desc {
                color: #999999;
            }
        }

        .video-pagination {
            text-align: right;
        }
    }
}

.model-video-player {
    width: 400px;
}
@primary-color: #7f7fd5;@link-color: #86a8e7;