.video-module .video-player {
  width: 270px;
  height: 150px;
  object-fit: fill;
  margin-top: 20px;
}
.video-list-body .video-list-box {
  height: 420px;
  margin: 8px;
}
.video-list-body .video-list-box .video-item {
  margin-top: 15px;
  border: 1px solid #fff;
  text-align: center;
  padding: 10px;
}
.video-list-body .video-list-box .video-item .video-item-player {
  width: 250px;
  height: 140px;
  object-fit: fill;
}
.video-list-body .video-list-box .video-item:hover {
  border: 1px solid #f2f2f2;
}
.video-list-body .video-footer {
  padding: 8px;
}
.video-list-body .video-footer .footer-operate .footer-desc {
  color: #999999;
}
.video-list-body .video-footer .video-pagination {
  text-align: right;
}
.model-video-player {
  width: 400px;
}
